import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Card, CardContent, Rating } from "@mui/material";
import { useAuth } from "./context/AuthContext";
import { db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";

function MyProfile() {
  const { currentUser } = useAuth();
  const [ratings, setRatings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRatings = async () => {
      if (!currentUser) return;

      try {
        const userDocRef = doc(db, "users", currentUser.email);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setRatings(userData.ratings || []);
        }
      } catch (error) {
        console.error("Error fetching ratings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRatings();
  }, [currentUser]);

  if (loading) {
    return <Typography>Loading your ratings...</Typography>;
  }

  return (
    <Box mt={4}>
      <Typography variant="h5" gutterBottom>
        My Profile Ratings
      </Typography>
      {ratings.length > 0 ? (
        <Grid container spacing={2}>
          {ratings.map((rating, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="body1">
                    <strong>Rating:</strong> <Rating value={rating.rating} readOnly />
                  </Typography>
                  <Typography variant="body1">
                    <strong>Comment:</strong> {rating.comment || "No comment provided"}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Reviewer:</strong> {rating.reviewer}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>No ratings or reviews yet.</Typography>
      )}
    </Box>
  );
}

export default MyProfile;
