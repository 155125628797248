import React from "react";
import { Box, Typography, Grid, Card, CardContent, Divider } from "@mui/material";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const SubmittedFlights = ({ flights }) => {
  return (
    <Box mt={4}>
      <Typography variant="h5" gutterBottom>
        ✈️ Your Submitted Flight Details
      </Typography>
      {flights.length > 0 ? (
        <Grid container spacing={3}>
          {flights.map((flight, index) => (
            <Grid item xs={12} key={index}>
              <Card
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  borderRadius: "10px",
                }}
              >
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Passenger Details
                  </Typography>
                  <Divider />
                  <Box mt={2}>
                    <Typography variant="body2">
                      <strong>Full Name:</strong> {flight.fullName}
                    </Typography>
                    <Typography variant="body2">
                      <strong>I am:</strong> {flight.companionType}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Phone:</strong> {flight.phoneNumber}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Notes:</strong> {flight.notes || "None"}
                    </Typography>
                  </Box>

                  <Typography variant="h6" mt={4}>
                    Flight Journey
                  </Typography>
                  <Divider />
                  <Box
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    mt={2}
                  >
                    {flight.segments.map((segment, segIndex) => (
                      <React.Fragment key={segIndex}>
                        <Box
                          style={{
                            backgroundColor: "#f5f5f5",
                            padding: "15px",
                            borderRadius: "10px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            margin: "5px",
                            minWidth: "250px",
                          }}
                        >
                          <Box display="flex" alignItems="center" mb={1}>
                            <FlightTakeoffIcon
                              style={{ color: "#4caf50", marginRight: "8px" }}
                            />
                            <Typography variant="body2">
                              <strong>From:</strong> {segment.departure} (
                              {segment.departureDate} at {segment.departureTime}
                              )
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <FlightLandIcon
                              style={{ color: "#f44336", marginRight: "8px" }}
                            />
                            <Typography variant="body2">
                              <strong>To:</strong> {segment.arrival} (
                              {segment.arrivalDate} at {segment.arrivalTime})
                            </Typography>
                          </Box>
                          <Divider style={{ margin: "8px 0" }} />
                          <Typography variant="body2">
                            <strong>Flight Number:</strong>{" "}
                            {segment.flightNumber}
                          </Typography>
                          <Typography variant="body2">
                            <strong>Airline:</strong> {segment.airline}
                          </Typography>
                        </Box>
                        {segIndex < flight.segments.length - 1 && (
                          <ArrowForwardIcon
                            style={{
                              margin: "0 15px",
                              color: "#1976d2",
                              fontSize: "32px",
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>No submitted flight details found.</Typography>
      )}
    </Box>
  );
};

export default SubmittedFlights;
