import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Alert,
  Checkbox,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { db } from "./firebase";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import { useAuth } from "./context/AuthContext";
import { useNavigate } from "react-router-dom";
import SubmittedFlights from "./SubmittedFlights";
import HaventBookedFlight from "./HaventBookedFlight";
import AcceptedConnections from "./AcceptedConnections";
import airlineOptions from "./airlineOptions";
import airportOptions from "./airportOptions";

const FlightForm = ({ setMatches }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  // Multi-leg flights state
  const [flightSegments, setFlightSegments] = useState([
    {
      flightNumber: "",
      airline: "",
      departure: "",
      departureDate: "",
      departureTime: "",
      arrival: "",
      arrivalDate: "",
      arrivalTime: "",
    },
  ]);
  

  const [flightDetails, setFlightDetails] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    age: "",
    notes: "",
    companionType: "",
  });

  const [selectedNotes, setSelectedNotes] = useState([]);
  const noteOptions = [
    "Can't speak English",
    "Senior citizen",
    "Parent travelling with a kid",
    "Wheelchair",
    "Long layover",
  ];

  const [connections, setConnections] = useState([]); // State for accepted connections
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loadingMatches, setLoadingMatches] = useState(false);
  const [submittedFlights, setSubmittedFlights] = useState([]); // Track submitted flights

  useEffect(() => {
    if (currentUser) {
      setFlightDetails((prevDetails) => ({
        ...prevDetails,
        email: currentUser.email,
        fullName: currentUser.displayName || currentUser.email.split("@")[0],
      }));

      // Fetch accepted connections and submitted flights
      fetchConnections();
      fetchSubmittedFlights();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const fetchConnections = async () => {
    try {
      const userDocRef = doc(db, "users", currentUser.email);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setConnections(userData.connections || []);
      }
    } catch (error) {
      console.error("Error fetching connections:", error);
    }
  };

  const fetchSubmittedFlights = async () => {
    try {
      const q = query(
        collection(db, "flightDetails"),
        where("email", "==", currentUser.email)
      );
      const querySnapshot = await getDocs(q);
      const flights = [];
      querySnapshot.forEach((doc) => {
        flights.push({ id: doc.id, ...doc.data() });
      });
      setSubmittedFlights(flights); // Update submitted flights
    } catch (error) {
      console.error("Error fetching submitted flights:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFlightDetails({
      ...flightDetails,
      [name]: value,
    });
  };

  const handleNotesChange = (option) => {
    const updatedNotes = selectedNotes.includes(option)
      ? selectedNotes.filter((note) => note !== option)
      : [...selectedNotes, option];
    setSelectedNotes(updatedNotes);
    setFlightDetails({ ...flightDetails, notes: updatedNotes.join(", ") });
  };

  // eslint-disable-next-line no-unused-vars
  const normalizeData = (details) => ({
    flightNumber: details.flightNumber.toLowerCase(),
    airline: details.airline.toLowerCase(),
    departure: details.departure.toLowerCase(),
    departureDate: details.departureDate,
    departureTime: details.departureTime,
    arrival: details.arrival.toLowerCase(),
    arrivalDate: details.arrivalDate,
    arrivalTime: details.arrivalTime,
  });

  const handleSegmentChange = (index, field, value) => {
    const updatedSegments = [...flightSegments];
    updatedSegments[index][field] = value;
    setFlightSegments(updatedSegments);
  };

  const addSegment = () => {
    setFlightSegments([
      ...flightSegments,
      {
        flightNumber: "",
        airline: "",
        date: "",
        departure: "",
        departureTime: "",
        arrival: "",
        arrivalTime: "",
      },
    ]);
  };

  const removeSegment = (index) => {
    const updatedSegments = flightSegments.filter((_, i) => i !== index);
    setFlightSegments(updatedSegments);
  };

  const normalizeField = (value) => (value ? value.toLowerCase().trim() : "");

  const normalizeSegments = (segments) => {
    return segments.map((segment) => ({
      flightNumber: normalizeField(segment.flightNumber),
      airline: normalizeField(segment.airline),
      departure: normalizeField(segment.departure),
      departureDate: normalizeField(segment.departureDate),
      departureTime: normalizeField(segment.departureTime),
      arrival: normalizeField(segment.arrival),
      arrivalDate: normalizeField(segment.arrivalDate),
      arrivalTime: normalizeField(segment.arrivalTime),
    }));
  };
  
  
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (
      flightSegments.some(
        (segment) =>
          !segment.flightNumber ||
          !segment.airline ||
          !segment.departure ||
          !segment.departureDate ||
          !segment.departureTime ||
          !segment.arrival ||
          !segment.arrivalDate ||
          !segment.arrivalTime
      )
    ) {
      setErrorMessage("Please complete all flight segment details.");
      return;
    }
    
  
    if (
      !flightDetails.fullName ||
      !flightDetails.phoneNumber ||
      !flightDetails.age ||
      !flightDetails.companionType
    ) {
      setErrorMessage("Please complete all passenger details.");
      return;
    }
  
    setLoadingMatches(true);
  
    try {
      const normalizedSegments = normalizeSegments(flightSegments);
      // eslint-disable-next-line no-unused-vars
      const normalizedDetails = {
        ...flightDetails,
        segments: normalizedSegments,
      };
  
      // Check for duplicate submissions
      const existingQuery = query(
        collection(db, "flightDetails"),
        where("email", "==", currentUser.email)
      );
      const existingSnapshot = await getDocs(existingQuery);
  
      let isDuplicate = false;
  
      existingSnapshot.forEach((doc) => {
        const data = doc.data();
        const existingSegments = normalizeSegments(data.segments || []);
        if (
          JSON.stringify(existingSegments) === JSON.stringify(normalizedSegments)
        ) {
          isDuplicate = true;
        }
      });
  
      if (isDuplicate) {
        setErrorMessage("You have already submitted these flight details.");
        setLoadingMatches(false);
        return;
      }
  
      // Add new flight details
      const docRef = await addDoc(collection(db, "flightDetails"), {
        ...flightDetails,
        segments: flightSegments,
      });
  
      // Matching Logic
      const matchQuery = query(collection(db, "flightDetails"));
      const matchSnapshot = await getDocs(matchQuery);
  
      const matchedItineraries = [];
      matchSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.email === currentUser.email) return; // Exclude current user's flights
  
        const existingSegments = normalizeSegments(data.segments || []);
        if (
          existingSegments.length === normalizedSegments.length &&
          existingSegments.every((segment, index) => {
            const submittedSegment = normalizedSegments[index];
            return (
              segment.flightNumber === submittedSegment.flightNumber &&
              segment.airline === submittedSegment.airline &&
              segment.departureDate === submittedSegment.departureDate &&
              segment.departureTime === submittedSegment.departureTime &&
              segment.arrivalDate === submittedSegment.arrivalDate &&
              segment.arrivalTime === submittedSegment.arrivalTime
            );
          })
        ) {
          matchedItineraries.push({ id: doc.id, ...data });
        }
      });
  
      setMatches(matchedItineraries);
  
      // Save matches to the user profile
      const userDocRef = doc(db, "users", flightDetails.email);
      await setDoc(
        userDocRef,
        { matches: arrayUnion(...matchedItineraries) },
        { merge: true }
      );
  
      // Update matches for matched users
      for (const match of matchedItineraries) {
        const matchedUserDocRef = doc(db, "users", match.email);
        const matchedUserDoc = await getDoc(matchedUserDocRef);

        if (matchedUserDoc.exists()) {
          const matchedUserData = matchedUserDoc.data();
          const existingMatches = matchedUserData.matches || [];
          const isAlreadyMatched = existingMatches.some(
            (m) => m.email === flightDetails.email
          );

          if (!isAlreadyMatched) {
            await setDoc(
              matchedUserDocRef,
              {
                matches: arrayUnion({
                  id: docRef.id,
                  ...flightDetails,
                  segments: flightSegments,
                }),
              },
              { merge: true }
            );
          }
        }
      }

      // Dynamically update submitted flights without refresh
      setSubmittedFlights((prev) => [
        ...prev,
        { id: docRef.id, ...flightDetails, segments: flightSegments },
      ]);
  
      // Reset fields
      setFlightSegments([
        {
          flightNumber: "",
          airline: "",
          departure: "",
          departureDate: "",
          departureTime: "",
          arrival: "",
          arrivalDate: "",
          arrivalTime: "",
        },
      ]);
      setFlightDetails({
        fullName: currentUser.displayName || currentUser.email.split("@")[0],
        email: currentUser.email,
        phoneNumber: "",
        age: "",
        notes: "",
        companionType: "",
      });
      setSelectedNotes([]);
  
      setSuccessMessage(
        matchedItineraries.length > 0
          ? "Matches found and Email Notificatons Sent! Redirecting to Matches..."
          : "No matches found. Your itinerary has been saved. Once a match is found, you will get an Email Notification."
      );
      setErrorMessage(null);
      if (matchedItineraries.length > 0) {
        setTimeout(() => navigate("/matches"), 3000);
      }
    } catch (error) {
      console.error("Error adding flight details:", error);
      setErrorMessage(`Failed to submit flight details: ${error.message}`);
    } finally {
      setLoadingMatches(false);
    }
  };

  return (
    <Box
      mt={4}
      p={4}
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        background: "#f9f9f9",
        borderRadius: "8px",
      }}
    >
      <AcceptedConnections connections={connections} />
      <HaventBookedFlight />

      {/* Looking for a Companion Section */}
      <Box mb={3}>
        <FormControl component="fieldset">
          <Typography variant="h5" gutterBottom>
            Get Started By Entering Your Flight Details:
          </Typography>
          <RadioGroup
            value={flightDetails.companionType}
            onChange={(e) =>
              setFlightDetails({
                ...flightDetails,
                companionType: e.target.value,
              })
            }
          >
            <FormControlLabel
              value="Looking for a companion"
              required
              control={<Radio color="primary" />}
              label="Looking for a companion"
            />
            <FormControlLabel
              value="Volunteering to be a companion"
              required
              control={<Radio color="primary" />}
              label="Volunteering to be a companion"
            />
          </RadioGroup>
        </FormControl>
      </Box>

      <form onSubmit={handleSubmit}>
        <Typography variant="h6" gutterBottom>
          Enter Your Flight Details (Including Layovers)
        </Typography>
        {flightSegments.map((segment, index) => (
          <Box
            key={index}
            mb={4}
            style={{
              border: "1px solid #ccc",
              padding: "16px",
              borderRadius: "8px",
            }}
          >
            <Typography variant="h6">Flight {index + 1}</Typography>
            <Grid container spacing={2} key={index}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  freeSolo
                  options={airlineOptions}
                  getOptionLabel={(option) => option.label || option}
                  renderInput={(params) => (
                    <TextField {...params} label="Airline" required />
                  )}
                  value={segment.airline || ""}
                  onChange={(event, value) =>
                    handleSegmentChange(
                      index,
                      "airline",
                      value?.label || value || ""
                    )
                  }
                  onInputChange={(event, value) =>
                    handleSegmentChange(index, "airline", value || "")
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Flight Number"
                  value={segment.flightNumber}
                  onChange={(e) =>
                    handleSegmentChange(index, "flightNumber", e.target.value)
                  }
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  freeSolo
                  options={airportOptions}
                  getOptionLabel={(option) => option.label || option}
                  renderInput={(params) => (
                    <TextField {...params} label="Departure Airport" required />
                  )}
                  value={segment.departure || ""}
                  onChange={(event, value) =>
                    handleSegmentChange(
                      index,
                      "departure",
                      value?.label || value || ""
                    )
                  }
                  onInputChange={(event, value) =>
                    handleSegmentChange(index, "departure", value || "")
                  }
                  fullWidth
                />
              </Grid>

              {/* Departure Date */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Departure Date"
                  type="date"
                  value={segment.departureDate}
                  onChange={(e) =>
                    handleSegmentChange(index, "departureDate", e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth
                />
              </Grid>

              {/* Departure Time */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Departure Time"
                  type="time"
                  value={segment.departureTime}
                  onChange={(e) =>
                    handleSegmentChange(index, "departureTime", e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth
                />
              </Grid>

              {/* Arrival Airport */}
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  freeSolo
                  options={airportOptions}
                  getOptionLabel={(option) => option.label || option}
                  renderInput={(params) => (
                    <TextField {...params} label="Arrival Airport" required />
                  )}
                  value={segment.arrival || ""}
                  onChange={(event, value) =>
                    handleSegmentChange(
                      index,
                      "arrival",
                      value?.label || value || ""
                    )
                  }
                  onInputChange={(event, value) =>
                    handleSegmentChange(index, "arrival", value || "")
                  }
                  fullWidth
                />
              </Grid>

              {/* Arrival Date */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Arrival Date"
                  type="date"
                  value={segment.arrivalDate}
                  onChange={(e) =>
                    handleSegmentChange(index, "arrivalDate", e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth
                />
              </Grid>

              {/* Arrival Time */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Arrival Time"
                  type="time"
                  value={segment.arrivalTime}
                  onChange={(e) =>
                    handleSegmentChange(index, "arrivalTime", e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth
                />
              </Grid>
            </Grid>

            {flightSegments.length > 1 && (
              <Button
                color="secondary"
                onClick={() => removeSegment(index)}
                style={{ marginTop: "16px" }}
              >
                Remove Flight
              </Button>
            )}
          </Box>
        ))}
        <Button
          onClick={addSegment}
          color="primary"
          variant="outlined"
          style={{ marginBottom: "16px" }}
        >
          Add Another Flight
        </Button>

        <Typography variant="h5" gutterBottom mt={3}>
          Passenger Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Full Name"
              name="fullName"
              value={flightDetails.fullName}
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone Number"
              name="phoneNumber"
              value={flightDetails.phoneNumber}
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Age"
              name="age"
              type="number"
              value={flightDetails.age}
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h5" gutterBottom mt={3}>
          Notes
        </Typography>
        <Grid container spacing={2}>
          {noteOptions.map((option) => (
            <Grid item xs={12} sm={6} key={option}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedNotes.includes(option)}
                    onChange={() => handleNotesChange(option)}
                  />
                }
                label={option}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <TextField
              label="Additional Notes"
              helperText="Click on the checkboxes above or type any other notes/preferences"
              name="notes"
              value={flightDetails.notes}
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
        </Grid>

        <Box mt={3} textAlign="center">
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: "#800040",
              color: "white",
              padding: "10px 20px",
            }}
            disabled={loadingMatches}
          >
            {loadingMatches ? "Submitting..." : "Submit"}
          </Button>
        </Box>
      </form>
      <Box mt={3}>
        {successMessage && (
          <Alert severity="success" style={{ marginBottom: "20px" }}>
            {successMessage}
          </Alert>
        )}
        {errorMessage && (
          <Alert severity="error" style={{ marginBottom: "20px" }}>
            {errorMessage}
          </Alert>
        )}
      </Box>

      {/* Submitted Flights Section */}
      <SubmittedFlights flights={submittedFlights} />
    </Box>
  );
};

export default FlightForm;
