import React, { useEffect, useState } from "react";
import { Box, Typography, Card, CardContent, Grid, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { db } from "./firebase";
import { doc, getDoc } from "firebase/firestore"; // Firestore functions
import { useAuth } from "./context/AuthContext";

function Matches() {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMatches = async () => {
      if (!currentUser) return;

      try {
        // Fetch the user's document from Firestore
        const userDocRef = doc(db, "users", currentUser.email);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.matches) {
            setMatches(userData.matches);
          } else {
            console.warn("No matches found in the user document.");
          }
        } else {
          console.warn("No user document found!");
        }
      } catch (error) {
        console.error("Error fetching matches:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMatches();
  }, [currentUser]);

  const handleCardClick = (match) => {
    navigate(`/user-profile/${match.id}`, { state: { match } });
  };

  if (loading) {
    return <Typography>Loading matches...</Typography>;
  }

  return (
    <Box mt={4}>
      {matches.length > 0 ? (
        <>
          <Typography variant="h5" gutterBottom>
            ✈️ Your Matches
          </Typography>
          <Grid container spacing={3}>
            {matches.map((match, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card
                  onClick={() => handleCardClick(match)}
                  style={{
                    cursor: "pointer",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {match.fullName}
                    </Typography>
                    <Divider />
                    <Box mt={2}>
                      <Typography variant="body2">
                        <strong>Age:</strong> {match.age || "N/A"}
                      </Typography>
                      <Typography variant="body2">
                        <strong>I am:</strong> {match.companionType}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Notes:</strong> {match.notes || "None"}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Typography variant="h6" gutterBottom textAlign="center">
          No matches found.
        </Typography>
      )}
    </Box>
  );
}

export default Matches;
