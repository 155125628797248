import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  Divider,
  Rating,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  onSnapshot,
  query,
  where,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "./firebase";
import { useAuth } from "./context/AuthContext";

function UserProfile() {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { match } = location.state || {}; // Match details passed via navigation state

  const [status, setStatus] = useState("idle"); // idle, pending, connected
  const [loading, setLoading] = useState(true); // Loading state
  const [ratings, setRatings] = useState([]); // Ratings for the profile

  useEffect(() => {
    if (!currentUser || !match) return;

    const unsubscribeRequests = onSnapshot(
      collection(db, "users", currentUser.email, "connectionRequests"),
      (snapshot) => {
        const requests = snapshot.docs.map((doc) => doc.data());
        const hasRequestFromCurrentUser = requests.some(
          (req) => req.senderEmail === match.email
        );

        if (hasRequestFromCurrentUser) {
          setStatus("pending");
        }
      }
    );

    const checkConnectionStatus = async () => {
      try {
        const userDocRef = doc(db, "users", match.email);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setRatings(userData.ratings || []);

          const isConnected = userData.connections?.some(
            (conn) => conn.email === currentUser.email
          );

          if (isConnected) {
            setStatus("connected");
          } else {
            const requestsRef = collection(
              db,
              "users",
              match.email,
              "connectionRequests"
            );
            const q = query(
              requestsRef,
              where("senderEmail", "==", currentUser.email)
            );
            const requestSnapshot = await getDocs(q);

            if (!requestSnapshot.empty) {
              setStatus("pending");
            }
          }
        }
      } catch (error) {
        console.error("Error checking connection status:", error);
      } finally {
        setLoading(false);
      }
    };

    checkConnectionStatus();

    return () => {
      unsubscribeRequests();
    };
  }, [currentUser, match]);

  const handleSendRequest = async () => {
    try {
      if (!currentUser) {
        alert("You need to log in to send connection requests.");
        navigate("/login");
        return;
      }

          // Fetch the current user's age from their profile
    const currentUserDocRef = doc(db, "users", currentUser.email);
    const currentUserDoc = await getDoc(currentUserDocRef);
    const currentUserAge = currentUserDoc.exists() ? currentUserDoc.data().age : "N/A";

      const recipientRequestsRef = collection(
        db,
        "users",
        match.email,
        "connectionRequests"
      );

      await addDoc(recipientRequestsRef, {
        senderEmail: currentUser.email,
        senderName: currentUser.displayName || currentUser.email.split("@")[0],
        segments: match.segments,
        age: match.age || 'N/A',
        notes: match.notes || "None",
        companionType: match.companionType,
        timestamp: new Date(),
      });

      setStatus("pending");
      alert(`Connection request sent to ${match.fullName}`);
    } catch (error) {
      console.error("Error sending connection request:", error);
      alert("Failed to send connection request. Please try again.");
    }
  };

  if (!match) {
    return <Typography>No user profile found.</Typography>;
  }

  return (
    <Box mt={4} p={4} style={{ maxWidth: "800px", margin: "0 auto" }}>
      <Card
        style={{
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          borderRadius: "10px",
        }}
      >
        <CardContent>
          <Typography variant="h4" gutterBottom>
            {match.fullName}'s Profile
          </Typography>
          <Divider style={{ margin: "20px 0" }} />
          <Typography variant="h6">Personal Details</Typography>
          <Typography variant="body1">
            <strong>Email:</strong> {match.email}
          </Typography>
          <Typography variant="body1">
            <strong>Age:</strong> {match.age || "N/A"}
          </Typography>
          <Typography variant="body1">
            <strong>Companion Type:</strong> {match.companionType}
          </Typography>
          <Typography variant="body1">
            <strong>Notes:</strong> {match.notes || "None"}
          </Typography>
          <Box
            style={{
              marginTop: "20px",
              padding: "15px",
              backgroundColor: "#e3f7ff",
              border: "1px solid #b3e5fc", 
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <InfoOutlinedIcon
              style={{
                color: "#0288d1",
                fontSize: "30px",
                marginRight: "15px",
              }}
            />
            <Typography
              variant="body2"
              style={{ color: "#333", fontSize: "1rem", lineHeight: "1.5" }}
            >
              You will be able to see contact info once both of you are
              connected or accept the connection request.
            </Typography>
          </Box>
          <Divider style={{ margin: "20px 0" }} />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="flight-journey-content"
              id="flight-journey-header"
            >
              <Typography variant="h6">Flight Journey</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" alignItems="center" flexWrap="wrap" mt={2}>
                {match.segments.map((segment, index) => (
                  <React.Fragment key={index}>
                    <Box
                      style={{
                        backgroundColor: "#f5f5f5",
                        padding: "15px",
                        borderRadius: "10px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        margin: "5px",
                        minWidth: "250px",
                      }}
                    >
                      <Box display="flex" alignItems="center" mb={1}>
                        <FlightTakeoffIcon
                          style={{ color: "#4caf50", marginRight: "8px" }}
                        />
                        <Typography
                          variant="body2"
                          style={{ fontWeight: "bold" }}
                        >
                          {segment.departure} ({segment.departureDate} at{" "}
                          {segment.departureTime})
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <FlightLandIcon
                          style={{ color: "#f44336", marginRight: "8px" }}
                        />
                        <Typography
                          variant="body2"
                          style={{ fontWeight: "bold" }}
                        >
                          {segment.arrival} ({segment.arrivalDate} at{" "}
                          {segment.arrivalTime})
                        </Typography>
                      </Box>
                      <Divider style={{ margin: "8px 0" }} />
                      <Typography variant="body2">
                        <strong>Flight Number:</strong> {segment.flightNumber}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Airline:</strong> {segment.airline}
                      </Typography>
                    </Box>
                    {index < match.segments.length - 1 && (
                      <ArrowForwardIcon
                        style={{
                          margin: "0 15px",
                          color: "#1976d2",
                          fontSize: "32px",
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Box
            mt={3}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gap={2}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            {loading ? (
              <Button variant="contained" disabled>
                Checking...
              </Button>
            ) : status === "connected" ? (
              <Button variant="contained" color="success" disabled>
                Connected
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendRequest}
                disabled={status === "pending"}
              >
                {status === "pending" ? "Pending" : "Send Connection Request"}
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          User Ratings and Reviews
        </Typography>
        {ratings.length > 0 ? (
          <Grid container spacing={2}>
            {ratings.map((rating, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="body1">
                      <strong>Rating:</strong>{" "}
                      <Rating value={rating.rating} readOnly />
                    </Typography>
                    <Typography variant="body1">
                      <strong>Comment:</strong>{" "}
                      {rating.comment || "No comment provided"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Reviewer:</strong> {rating.reviewer}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>No ratings or reviews yet.</Typography>
        )}
      </Box>
    </Box>
  );
}

export default UserProfile;
