import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Card,
  CardContent,
  Button,
  Alert,
  CardActions,
} from "@mui/material";
import { db } from "./firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext";

const SearchVolunteers = () => {
  const [month, setMonth] = useState("");
  const [volunteers, setVolunteers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isVolunteer, setIsVolunteer] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    const checkIfVolunteer = async () => {
      if (!currentUser) return;

      try {
        const q = query(
          collection(db, "flightDetails"),
          where("email", "==", currentUser.email),
          where("companionType", "==", "Volunteering to be a companion")
        );
        const querySnapshot = await getDocs(q);
        setIsVolunteer(!querySnapshot.empty);
      } catch (err) {
        console.error("Error checking volunteer status:", err);
      }
    };

    checkIfVolunteer();
  }, [currentUser]);

  const handleSearch = async () => {
    if (!month) {
      setError("Please select a month.");
      return;
    }
    setError(null);
    setLoading(true);
  
    try {
      const q = query(
        collection(db, "flightDetails"),
        where("companionType", "==", "Volunteering to be a companion")
      );
      const querySnapshot = await getDocs(q);
  
      const fetchedVolunteers = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const segments = data.segments || [];
  
        // Filter segments by departureDate range
        const validSegments = segments.filter((segment) => {
          const segmentDate = segment.departureDate; // Ensure segment.departureDate exists
          return segmentDate >= `${month}-01` && segmentDate <= `${month}-31`;
        });
  
        // Push only if there's at least one valid segment
        if (validSegments.length > 0) {
          fetchedVolunteers.push({
            id: doc.id,
            fullName: data.fullName || "Unknown",
            flightNumber: validSegments[0]?.flightNumber || "N/A",
            airline: validSegments[0]?.airline || "N/A",
            departureDate: validSegments[0]?.departureDate || "N/A",
            departureTime: validSegments[0]?.departureTime || "N/A",
            arrivalDate: validSegments[0]?.arrivalDate || "N/A",
            arrivalTime: validSegments[0]?.arrivalTime || "N/A",
            departure: validSegments[0]?.departure || "N/A",
            arrival: validSegments[0]?.arrival || "N/A",
            age: data.age || "N/A",
            notes: data.notes || "None",
          });
        }
      });
  
      setVolunteers(fetchedVolunteers);
    } catch (err) {
      console.error("Error fetching volunteers:", err);
      setError("Failed to fetch volunteers. Please try again.");
    } finally {
      setLoading(false);
    }
  };
   

  return (
    <Box
      mt={4}
      p={4}
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        background: "#f9f9f9",
        borderRadius: "8px",
      }}
    >
      {isVolunteer && (
        <Box mb={4}>
          <Card
            style={{
              background: "#f0f4ff",
              borderRadius: "12px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              padding: "20px",
            }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Manage Your Volunteer Details
              </Typography>
              <Typography variant="body2" color="textSecondary">
                You’ve signed up to be a volunteer companion. Click below to
                review and manage your volunteer flight details.
              </Typography>
            </CardContent>
            <CardActions style={{ justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/manage-volunteer-details")}
                style={{
                  padding: "10px 20px",
                  textTransform: "capitalize",
                }}
              >
                Manage My Volunteer Details
              </Button>
            </CardActions>
          </Card>
        </Box>
      )}

      <Typography variant="h5" gutterBottom>
        Search for Volunteer Companions
      </Typography>

      <Box mb={3}>
        <TextField
          label="Select Month"
          type="month"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          style={{
            marginBottom: "16px",
          }}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          style={{ display: "block", margin: "0 auto" }}
        >
          Search
        </Button>
      </Box>

      {error && (
        <Alert severity="error" style={{ marginBottom: "16px" }}>
          {error}
        </Alert>
      )}

      {loading && <Typography>Loading...</Typography>}

      {volunteers.length > 0 && (
        <Box mt={4}>
          <Typography variant="h6" gutterBottom>
            Volunteer Companions
          </Typography>
          <Grid container spacing={2}>
            {volunteers.map((volunteer) => (
              <Grid item xs={12} sm={6} key={volunteer.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">{volunteer.fullName}</Typography>
                    <Typography variant="body2">
                      <strong>Flight Number:</strong> {volunteer.flightNumber}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Airline:</strong> {volunteer.airline}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Departure:</strong> {volunteer.departure} (
                      {volunteer.departureDate} at {volunteer.departureTime})
                    </Typography>
                    <Typography variant="body2">
                      <strong>Arrival:</strong> {volunteer.arrival} (
                      {volunteer.arrivalDate} at {volunteer.arrivalTime})
                    </Typography>
                    <Typography variant="body2">
                      <strong>Age:</strong> {volunteer.age}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Notes:</strong> {volunteer.notes || "None"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {!loading && volunteers.length === 0 && (
        <Typography>No volunteers found for the selected month.</Typography>
      )}
    </Box>
  );
};

export default SearchVolunteers;
