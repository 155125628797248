import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  Typography,
  Alert,
  Grid,
} from "@mui/material";
import { auth } from "./firebase";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";

function Signup() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showResend, setShowResend] = useState(false); // Track if Resend Email option should be shown
  const navigate = useNavigate(); // React Router navigation

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const isValidEmail = (email) => {
    // Regex pattern for valid email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    const { name, email, password, confirmPassword } = formData;

    // Validate required fields
    if (!name || !email || !password || !confirmPassword) {
      setError("All fields are required.");
      return;
    }

    // Validate email format
    if (!isValidEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    // Validate password match
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      // Create user account
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Send email verification
      await sendEmailVerification(user);

      setSuccess("Account created successfully! Please verify your email before logging in.");
      setShowResend(true); // Show Resend Email button
      setFormData({ name: "", email: "", password: "", confirmPassword: "" }); // Clear the form
    } catch (err) {
      setError(err.message);
    }
  };

  const resendVerificationEmail = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        await sendEmailVerification(user);
        setSuccess("Verification email sent! Check your inbox.");
      } else {
        setError("No user is currently logged in.");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Box mt={4} p={4} maxWidth="400px" margin="0 auto">
      <Typography variant="h5" gutterBottom>
        Sign Up
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Full Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          type="email"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          type="password"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Confirm Password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          type="password"
          fullWidth
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Sign Up
        </Button>
      </form>

      {/* Error and Success Alerts */}
      {error && (
        <Alert severity="error" style={{ marginTop: "20px" }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" style={{ marginTop: "20px" }}>
          {success}
        </Alert>
      )}

      {/* Conditionally show Resend Verification Email button */}
      {showResend && (
        <Grid container justifyContent="center" style={{ marginTop: "10px" }}>
          <Grid item>
            <Button
              variant="text"
              color="primary"
              onClick={resendVerificationEmail}
              style={{ textTransform: "none" }}
            >
              Didn't receive verification email? Send again
            </Button>
          </Grid>
        </Grid>
      )}

      <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
        <Grid item>
          <Typography variant="body2">
            Already signed up?{" "}
            <Button
              variant="text"
              color="primary"
              onClick={() => navigate("/login")}
              style={{ textTransform: "none" }}
            >
              Login
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Signup;
