import React, { useState } from "react";
import { TextField, Button, Typography, Container, Box } from "@mui/material";
import { collection, addDoc } from "firebase/firestore";
import { db } from "./firebase"; // Import Firestore instance

function FeedbackForm() {
  const [feedback, setFeedback] = useState("");
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Add feedback to Firestore
      await addDoc(collection(db, "feedback"), {
        email: email || "Anonymous", // Add email if provided, or default to Anonymous
        feedback: feedback,
        timestamp: new Date(),
      });
      setSubmitted(true); // Show thank-you message
      setFeedback(""); // Clear the feedback field
      setEmail(""); // Clear the email field
    } catch (error) {
      console.error("Error adding feedback to Firestore: ", error);
    }
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        textAlign: "center",
        padding: "30px",
        marginTop: "50px",
        borderRadius: "10px",
        background: "#f9f9f9",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography variant="h5" gutterBottom>
        We Value Your Feedback
      </Typography>
      <Typography variant="body1" gutterBottom>
        Let us know your thoughts or suggestions to improve our platform.
      </Typography>
      {submitted ? (
        <Typography variant="h6" color="primary" gutterBottom>
          Thank you for your feedback!
        </Typography>
      ) : (
        <form onSubmit={handleSubmit}>
          <Box mt={2}>
            <TextField
              label="Your Email (optional)"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
          <Box mt={2}>
            <TextField
              label="Your Feedback"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              required
            />
          </Box>
          <Box mt={3}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Container>
  );
}

export default FeedbackForm;
