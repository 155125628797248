import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  Typography,
  Alert,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { auth } from "./firebase";
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useAuth } from "./context/AuthContext";

function Login() {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [error, setError] = useState(null);
  const [unverifiedUser, setUnverifiedUser] = useState(null);
  const [showResend, setShowResend] = useState(false);
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetMessage, setResetMessage] = useState("");
  const [resetError, setResetError] = useState(""); // Error specific to reset email
  const [showResetResend, setShowResetResend] = useState(false); // Track "Didn't receive reset email" functionality
  const navigate = useNavigate();
  const { refreshUser } = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleResetPassword = async () => {
    setResetError(null);
    setResetMessage(""); // Clear previous messages

    if (!resetEmail.trim()) {
      setResetError("Email is required.");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetMessage("Password reset email sent! Check your inbox.");
      setShowResetResend(true); // Show "Didn't receive reset email" button
    } catch (err) {
      setResetError(err.message);
    }
  };

  const handleResetDialogOpen = () => {
    setResetEmail(""); // Clear email field when opening dialog
    setResetMessage(""); // Clear previous messages
    setResetError(""); // Clear errors
    setShowResetResend(false); // Reset resend functionality
    setResetDialogOpen(true);
  };

  const handleResetDialogClose = () => {
    setResetEmail(""); // Clear email field when closing dialog
    setResetMessage(""); // Clear previous messages
    setResetError(""); // Clear errors
    setShowResetResend(false);
    setResetDialogOpen(false);
  };

  const resendVerificationEmail = async () => {
    try {
      if (unverifiedUser) {
        await sendEmailVerification(unverifiedUser);
        alert("Verification email sent!");
      } else {
        setError("Unable to resend verification email. Please try logging in again.");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setShowResend(false); // Reset the Resend Email button
  
    if (!formData.email.trim()) {
      setError("Email is required.");
      return;
    }
  
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      const user = userCredential.user;
  
      // Refresh the user state to check emailVerified
      await refreshUser();
  
      if (!auth.currentUser.emailVerified) {
        setUnverifiedUser(user);
        setShowResend(true);
        await signOut(auth);
        throw new Error("Please verify your email before logging in.");
      }
  
      navigate("/itinerary");
    } catch (err) {  
      // Map Firebase error codes to user-friendly messages
      const firebaseErrorMessages = {
        "auth/user-not-found": "No user found with this email.",
        "auth/wrong-password": "Invalid email or password.",
        "auth/invalid-email": "Please enter a valid email address.",
        "auth/invalid-credential": "Invalid email or password.",
        "auth/too-many-requests":
          "Too many login attempts. Please try again later.",
      };
  
      const customMessage =
        firebaseErrorMessages[err.code] || "An error occurred. Please try again.";
      setError(customMessage);
    }
  };

  return (
    <Box mt={4} p={4} maxWidth="400px" margin="0 auto">
      <Typography variant="h5" gutterBottom>
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          type="email"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          type="password"
          fullWidth
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Login
        </Button>
      </form>
      {error && <Alert severity="error" style={{ marginTop: "20px" }}>{error}</Alert>}

      {/* Show Resend Verification Email button if the user is not verified */}
      {showResend && (
        <Grid container justifyContent="center" style={{ marginTop: "10px" }}>
          <Grid item>
            <Button
              variant="text"
              color="primary"
              onClick={resendVerificationEmail}
              style={{ textTransform: "none" }}
            >
              Didn't receive verification email? Send again
            </Button>
          </Grid>
        </Grid>
      )}

      <Grid container justifyContent="center" style={{ marginTop: "10px" }}>
        <Grid item>
          <Button
            variant="text"
            color="primary"
            onClick={handleResetDialogOpen}
            style={{ textTransform: "none" }}
          >
            Forgot Password?
          </Button>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
        <Grid item>
          <Typography variant="body2">
            Not a user?{" "}
            <Button
              variant="text"
              color="primary"
              onClick={() => navigate("/signup")}
              style={{ textTransform: "none" }}
            >
              Sign up
            </Button>
          </Typography>
        </Grid>
      </Grid>

      {/* Forgot Password Dialog */}
      <Dialog open={resetDialogOpen} onClose={handleResetDialogClose}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your email address and we'll send you a link to reset your password.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            required
          />
          {resetMessage && (
            <Alert severity="success" style={{ marginTop: "10px" }}>
              {resetMessage}
            </Alert>
          )}
          {resetError && (
            <Alert severity="error" style={{ marginTop: "10px" }}>
              {resetError}
            </Alert>
          )}
          {showResetResend && (
            <Grid container justifyContent="center" style={{ marginTop: "10px" }}>
              <Grid item>
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleResetPassword}
                  style={{ textTransform: "none" }}
                >
                  Didn't receive reset email? Send again
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResetDialogClose} color="secondary">
            Close
          </Button>
          <Button onClick={handleResetPassword} color="primary">
            Send Reset Email
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Login;
