import React from "react";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const AcceptedConnections = ({ connections }) => {
  return (
    <Box mb={4}>
      <Typography variant="h6" gutterBottom>
        Your Flight Friends (Accepted Connections)
      </Typography>
      {connections.length > 0 ? (
        <Grid container spacing={2}>
          {connections.map((connection, index) => (
            <Grid item xs={12} key={index}>
              <Card
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  borderRadius: "10px",
                }}
              >
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {connection.fullName || connection.email}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Age:</strong> {connection.age || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Notes:</strong> {connection.notes || "None"}
                  </Typography>

                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ marginTop: "20px" }}
                  >
                    Flight Journey
                  </Typography>
                  <Box display="flex" alignItems="center" flexWrap="wrap">
                    {connection.segments.map((segment, segIndex) => (
                      <React.Fragment key={segIndex}>
                        <Box
                          style={{
                            backgroundColor: "#f5f5f5",
                            padding: "15px",
                            borderRadius: "10px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            margin: "5px",
                            minWidth: "250px",
                          }}
                        >
                          <Typography variant="body2">
                            <strong>Flight Number:</strong>{" "}
                            {segment.flightNumber}
                          </Typography>
                          <Typography variant="body2">
                            <strong>Airline:</strong> {segment.airline}
                          </Typography>
                          <Typography variant="body2">
                            <strong>From:</strong> {segment.departure} on{" "}
                            {segment.departureDate} at {segment.departureTime}
                          </Typography>
                          <Typography variant="body2">
                            <strong>To:</strong> {segment.arrival} on{" "}
                            {segment.arrivalDate} at {segment.arrivalTime}
                          </Typography>
                        </Box>
                        {segIndex < connection.segments.length - 1 && (
                          <ArrowForwardIcon
                            style={{
                              margin: "0 15px",
                              color: "#1976d2",
                              fontSize: "32px",
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </Box>

                  <Typography
                    variant="body2"
                    style={{
                      marginTop: "20px",
                      color: "#25D366",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                      alt="WhatsApp"
                      style={{
                        width: "20px",
                        height: "20px",
                        verticalAlign: "middle",
                        marginRight: "5px",
                      }}
                    />
                    WhatsApp: {connection.phoneNumber || "N/A"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>No accepted connections yet.</Typography>
      )}
    </Box>
  );
};

export default AcceptedConnections;
