const airlineOptions = [
    // International Airlines
    { label: "Qatar Airways" },
    { label: "Air India" },
    { label: "Korean Air" },
    { label: "Turkish Airlines" },
    { label: "Thai Airways" },
    { label: "Etihad Airways" },
    { label: "China Southern Airlines" },
    { label: "Emirates" },
    // National Airlines (US-based)
    { label: "American Airlines" },
    { label: "Delta Air Lines" },
    { label: "United Airlines" },
    { label: "Southwest Airlines" },
    { label: "Alaska Airlines" },
    { label: "JetBlue Airways" },
    { label: "Spirit Airlines" },
    { label: "Frontier Airlines" },

  ];
  
  export default airlineOptions;  