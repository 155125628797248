import React from "react";
import { Button, Typography, Box, Container, Grid, Card, CardContent, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import PeopleIcon from "@mui/icons-material/People";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import SecurityIcon from "@mui/icons-material/Security";
import FlightIcon from "@mui/icons-material/Flight";
import SearchIcon from "@mui/icons-material/Search";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";

function Welcome() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const handleGetStarted = () => {
    if (currentUser) {
      navigate("/itinerary");
    } else {
      navigate("/login");
    }
  };

  return (
    <Box>
      {/* Main Welcome Section */}
      <Container
        maxWidth="sm"
        style={{
          textAlign: "center",
          padding: "50px",
          marginTop: "50px",
          borderRadius: "10px",
          background: "#f9f9f9",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Welcome to Flight Friend
        </Typography>
        <Typography variant="body1" gutterBottom>
          We help you find flight companions, especially for individuals like
          senior citizens who may need company during their journey. Volunteer
          to be a companion or find a companion today!
        </Typography>
        <Box mt={4}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleGetStarted}
          >
            Get Started
          </Button>
        </Box>
        <Box mt={4}>
          <Typography variant="body1">
            Haven't booked a flight yet? Search for a specific timeline in our
            database and find companions and book your flight accordingly.
          </Typography>
        </Box>
      </Container>

      {/* Horizontal Cards Section */}
      <Container maxWidth="lg" style={{ marginTop: "30px" }}>
        <Grid container spacing={3}>
          {/* Card 1 */}
          <Grid item xs={12} sm={6} md={4}>
            <Card
              style={{
                height: "100%",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                textAlign: "center",
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <PeopleIcon color="primary" />
                  Companionship for Travelers
                </Typography>
                <Typography variant="body2">
                  Find companions for your journey to make traveling safer and
                  more enjoyable, especially for those traveling alone or
                  needing assistance.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {/* Card 2 */}
          <Grid item xs={12} sm={6} md={4}>
            <Card
              style={{
                height: "100%",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                textAlign: "center",
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <VolunteerActivismIcon color="primary" />
                  Volunteer Opportunities
                </Typography>
                <Typography variant="body2">
                  Become a travel companion and assist fellow travelers, making
                  a positive impact and helping others enjoy their journey.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {/* Card 3 */}
          <Grid item xs={12} sm={6} md={4}>
            <Card
              style={{
                height: "100%",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                textAlign: "center",
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <SecurityIcon color="primary" />
                  Secure Connections
                </Typography>
                <Typography variant="body2">
                  Connect with verified users and enjoy a safe platform designed
                  to match you with the perfect travel companions.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {/* How It Works Section */}
      <Container maxWidth="lg" style={{ marginTop: "50px" }}>
        <Typography variant="h4" gutterBottom align="center">
          How It Works
        </Typography>
        <Grid container spacing={3} style={{ marginTop: "20px" }}>
          {/* Step 1 */}
          <Grid item xs={12} sm={4}>
            <Card
              style={{ textAlign: "center", padding: "20px", height: "100%" }}
            >
              <FlightIcon color="primary" style={{ fontSize: "40px" }} />
              <Typography variant="h6" style={{ marginTop: "10px" }}>
                Step 1: Add Your Itinerary
              </Typography>
              <Typography variant="body2">
                Enter your flight details, including departure and arrival
                times, to get started.
              </Typography>
            </Card>
          </Grid>
          {/* Step 2 */}
          <Grid item xs={12} sm={4}>
            <Card
              style={{ textAlign: "center", padding: "20px", height: "100%" }}
            >
              <SearchIcon color="primary" style={{ fontSize: "40px" }} />
              <Typography variant="h6" style={{ marginTop: "10px" }}>
                Step 2: Find Matches
              </Typography>
              <Typography variant="body2">
                We scan our database to find the best matches for your flight
                details.
              </Typography>
            </Card>
          </Grid>
          {/* Step 3 */}
          <Grid item xs={12} sm={4}>
            <Card
              style={{ textAlign: "center", padding: "20px", height: "100%" }}
            >
              <ConnectWithoutContactIcon
                color="primary"
                style={{ fontSize: "40px" }}
              />
              <Typography variant="h6" style={{ marginTop: "10px" }}>
                Step 3: Connect
              </Typography>
              <Typography variant="body2">
                Send connection requests and chat with your travel companion
                before your flight.
              </Typography>
            </Card>
          </Grid>
        </Grid>
        <Box mt={6} textAlign="center">
          <Typography variant="body2">
            Have suggestions or feedback?{" "}
            <Button
              variant="text"
              onClick={() => navigate("/feedback")}
              style={{ textTransform: "none", color: "blue" }}
            >
              Click here to let us know.
            </Button>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Welcome;
