import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import Tesseract from "tesseract.js";

const FlightForm = () => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [flightDetails, setFlightDetails] = useState([]);
  const [ocrRawText, setOcrRawText] = useState("");

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedImage(file);
      processImage(file);
    }
  };

  const processImage = (image) => {
    Tesseract.recognize(image, "eng", {
      logger: (info) => console.log(info), // Logs OCR progress
    })
      .then(({ data: { text } }) => {
        console.log("OCR Output:", text);
        setOcrRawText(text);
        parseFlightDetails(text);
      })
      .catch((error) => console.error("OCR Error:", error));
  };

  const parseFlightDetails = (text) => {
    const lines = text.split("\n").map((line) => line.trim());
    const flights = [];
    let currentFlight = {};

    lines.forEach((line) => {
      // Match patterns for flight details
      const flightNumberMatch = line.match(/(?:Flight\s*#|Flight\s*Number|FL|Flight)\s*:?[\s]*(\w+)/i);
      const airlineMatch = line.match(/(?:Airline|Carrier):?\s*([\w\s]+)/i);
      const departureMatch = line.match(/(?:From|Departure|Departs):?\s*([\w\s,-]+)/i);
      const arrivalMatch = line.match(/(?:To|Arrival|Arrives):?\s*([\w\s,-]+)/i);
      const dateMatch = line.match(/(?:Date|Departure Date|Arrival Date):?\s*([\w\s,-]+)/i);
      const timeMatch = line.match(/(?:Time|Departure Time|Arrival Time):?\s*([\d:AMPamp]+)/i);

      if (flightNumberMatch) currentFlight.flightNumber = flightNumberMatch[1];
      if (airlineMatch) currentFlight.airline = airlineMatch[1];
      if (departureMatch) currentFlight.departure = departureMatch[1];
      if (arrivalMatch) currentFlight.arrival = arrivalMatch[1];
      if (dateMatch) currentFlight.date = dateMatch[1];
      if (timeMatch) currentFlight.time = timeMatch[1];

      // If all fields are captured, push to flights array
      if (
        currentFlight.flightNumber &&
        currentFlight.airline &&
        currentFlight.departure &&
        currentFlight.arrival &&
        currentFlight.date &&
        currentFlight.time
      ) {
        flights.push({ ...currentFlight });
        currentFlight = {}; // Reset for next flight
      }
    });

    if (flights.length === 0) {
      console.error("No flight details extracted. Please refine the parsing.");
    }

    setFlightDetails(flights);
  };

  return (
    <Box mt={4} p={4}>
      <Typography variant="h5" gutterBottom>
        Upload Your Itinerary
      </Typography>
      <input type="file" accept="image/*" onChange={handleImageUpload} />
      {uploadedImage && (
        <Box mt={2}>
          <Typography variant="body1">Uploaded Image:</Typography>
          <img
            src={URL.createObjectURL(uploadedImage)}
            alt="Uploaded Itinerary"
            style={{ maxWidth: "100%" }}
          />
        </Box>
      )}
      {ocrRawText && (
        <Card style={{ marginTop: "20px", padding: "20px" }}>
          <Typography variant="h6">OCR Extracted Text:</Typography>
          <Typography variant="body2">{ocrRawText}</Typography>
        </Card>
      )}
      {flightDetails.length > 0 && (
        <Card style={{ marginTop: "20px", padding: "20px" }}>
          <Typography variant="h6">Extracted Flight Details:</Typography>
          {flightDetails.map((flight, index) => (
            <CardContent key={index}>
              <Typography>Flight Number: {flight.flightNumber}</Typography>
              <Typography>Airline: {flight.airline}</Typography>
              <Typography>From: {flight.departure}</Typography>
              <Typography>To: {flight.arrival}</Typography>
              <Typography>Date: {flight.date}</Typography>
              <Typography>Time: {flight.time}</Typography>
            </CardContent>
          ))}
        </Card>
      )}
    </Box>
  );
};

export default FlightForm;
