import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Typography, Button, Box, Badge } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import { db } from "./firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

function Header() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [matchesCount, setMatchesCount] = useState(0);
  const [requestsCount, setRequestsCount] = useState(0);

  useEffect(() => {
    if (!currentUser) return;

    // Listen for real-time updates to matches
    const matchesUnsubscribe = onSnapshot(
      doc(db, "users", currentUser.email),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          const matches = userData.matches || [];
          setMatchesCount(matches.length);
        } else {
          setMatchesCount(0);
        }
      },
      (error) => {
        console.error("Error fetching matches updates:", error);
      }
    );

    // Listen for incoming connection requests
    const requestsUnsubscribe = onSnapshot(
      collection(db, "users", currentUser.email, "connectionRequests"),
      (snapshot) => {
        setRequestsCount(snapshot.size);
      },
      (error) => {
        console.error("Error fetching connection requests updates:", error);
      }
    );

    // Monitor outgoing requests to ensure the UI updates when a request is sent
    const outgoingRequestsUnsubscribe = onSnapshot(
      query(
        collection(db, "users"),
        where("connectionRequests", "array-contains", { senderEmail: currentUser.email })
      ),
      (snapshot) => {
        setRequestsCount((prev) => prev + snapshot.size); // Increment based on outgoing requests
      },
      (error) => {
        console.error("Error fetching outgoing connection requests updates:", error);
      }
    );

    // Cleanup listeners on unmount
    return () => {
      matchesUnsubscribe();
      requestsUnsubscribe();
      outgoingRequestsUnsubscribe();
    };
  }, [currentUser]);

  const handleLogout = async () => {
    try {
      await logout();
      setMatchesCount(0); // Clear matches count on logout
      setRequestsCount(0); // Clear requests count on logout
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error.message);
    }
  };

  return (
    <AppBar position="static" style={{ backgroundColor: "#800040" }}>
      <Toolbar>
        <Typography
          variant="h6"
          style={{ flexGrow: 1, cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          Flight Friend
        </Typography>
        <Box>
          <Button
            color="inherit"
            component={Link}
            to="/itinerary"
            style={{ textTransform: "none" }}
          >
            Home
          </Button>
          <Button
            color="inherit"
            component={Link}
            to="/matches"
            style={{ textTransform: "none", position: "relative" }}
          >
            <Badge
              badgeContent={matchesCount}
              color="error"
              invisible={matchesCount === 0}
            >
              Matches
            </Badge>
          </Button>
          {currentUser && currentUser.emailVerified ? (
            <>
              <Button
                color="inherit"
                component={Link}
                to="/connection-requests"
                style={{ textTransform: "none", position: "relative" }}
              >
                <Badge
                  badgeContent={requestsCount}
                  color="error"
                  invisible={requestsCount === 0}
                >
                  Connection Requests
                </Badge>
              </Button>
              <Button
                color="inherit"
                onClick={() => navigate("/my-profile")}
                style={{ textTransform: "none" }}
              >
                Welcome, {currentUser.email.split("@")[0]}
              </Button>
              <Button
                color="inherit"
                onClick={handleLogout}
                style={{ textTransform: "none" }}
              >
                Logout
              </Button>
            </>
          ) : (
            <Button
              color="inherit"
              component={Link}
              to="/login"
              style={{ textTransform: "none" }}
            >
              Login
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
