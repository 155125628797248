const airportOptions = [
    // Nepal Airports
    { label: "Tribhuvan International Airport (KTM)" },
    { label: "Doha Hamad International (DOH)" },
    { label: "Istanbul Airport (IST)" },
    { label: "Abu Dhabi International (AUH)" },
    { label: "Delhi Indira Gandhi International (DEL)" },
    { label: "Dubai International (DXB)" },
    { label: "Mumbai Chhatrapati Shivaji Maharaj International (BOM)" },
    // U.S. Airports (Preference for US-to-Nepal Flights)
    { label: "John F. Kennedy International (JFK)" },
    { label: "Los Angeles International (LAX)" },
    { label: "San Francisco International (SFO)" },
    { label: "Dallas/Fort Worth International (DFW)" },
    { label: "Washington Dulles International (IAD)" },
    { label: "Chicago O'Hare International (ORD)" },
    { label: "Boston Logan International (BOS)" },
    
    
    // Other Popular U.S. Airports
    { label: "Hartsfield-Jackson Atlanta International (ATL)" },
    { label: "Miami International (MIA)" },
    { label: "Seattle-Tacoma International (SEA)" },
    { label: "Denver International (DEN)" },
    { label: "Houston George Bush Intercontinental (IAH)" },
    
    // International Airports
    { label: "Heathrow Airport (LHR)" },
    { label: "Singapore Changi Airport (SIN)" },
    { label: "Hong Kong International (HKG)" },
    { label: "Tokyo Narita International (NRT)" },
    { label: "Seoul Incheon International (ICN)" },
    { label: "Frankfurt am Main Airport (FRA)" },
    { label: "Paris Charles de Gaulle (CDG)" },
    { label: "Amsterdam Schiphol (AMS)" },
    { label: "Sydney Kingsford Smith Airport (SYD)" },
    { label: "Toronto Pearson International (YYZ)" },
    { label: "Vancouver International (YVR)" },
    { label: "Melbourne Airport (MEL)" },
    { label: "Bangkok Suvarnabhumi Airport (BKK)" },
    { label: "Kuala Lumpur International (KUL)" },
    { label: "Beijing Capital International (PEK)" },
    { label: "Shanghai Pudong International (PVG)" },
    { label: "Jakarta Soekarno-Hatta International (CGK)" },
    { label: "Zurich Airport (ZRH)" },
    { label: "Madrid Barajas (MAD)" },
    { label: "Rome Fiumicino (FCO)" },
    { label: "Vienna International (VIE)" },
    { label: "Copenhagen Airport (CPH)" },
    { label: "Stockholm Arlanda Airport (ARN)" },
    { label: "Oslo Gardermoen Airport (OSL)" },
    { label: "Dubai Al Maktoum International (DWC)" },
    { label: "Ethiopian Airlines Hub - Bole International (ADD)" },
  ];
  
  export default airportOptions;
  