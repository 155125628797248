import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

const HaventBookedFlight = () => {
  const navigate = useNavigate();

  const handleHaventBookedFlight = () => {
    navigate("/search-volunteers");
  };

  return (
    <Box mb={3}>
      <Accordion
        style={{
          width: "100%",
          border: "1px solid #e0e0e0",
          borderRadius: "6px",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="haven't-booked-flight-content"
          id="haven't-booked-flight-header"
          style={{
            backgroundColor: "#f1f1f1",
            borderBottom: "1px solid #dcdcdc",
            padding: "10px 16px",
          }}
        >
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Haven't Booked a Flight Yet?
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            backgroundColor: "#fafafa",
            padding: "20px",
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="body1"
              color="textPrimary"
              style={{
                marginBottom: "16px",
                textAlign: "center",
                lineHeight: "1.8",
                fontSize: "16px",
              }}
            >
              If you haven't booked your flight yet and are looking for a
              companion, use this feature to explore volunteers who have already
              signed up to be a companion. Coordinate your plans and book your
              flight according to their itinerary for a more enjoyable travel
              experience.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleHaventBookedFlight}
              style={{
                fontSize: "16px",
                textTransform: "none",
                padding: "10px 20px",
                borderRadius: "8px",
                boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              Search for Volunteers
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default HaventBookedFlight;
